<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <transition name="fade" mode="out-in">
                    <div v-if="waitingList && waitingList.length !== 0" class="mt-4" key="liste">
                        <waiting-list-wrapper class="mt-3" :waitingList="waitingList"></waiting-list-wrapper>
                    </div>
                    <div v-else-if="waitingList && waitingList.length === 0" class="mt-5 text-center" key="aucun-rdv">
                        <h5>{{$t('liste-attente.aucun-rdv')}}</h5>
                        <p class="mt-3">{{$t('liste-attente.revenir-plus-tard')}}</p>
                    </div>
                    <table-skeleton v-else class="mt-3">
                    </table-skeleton>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import WaitingListWrapper from "../../../components/back/liste_attente/WaitingListWrapper";
    import axios from 'axios'
    import { mapGetters } from "vuex";
    import { namespaces } from "@/store/namespaces";
    import {EventBus} from "@/event-bus";
    import TableSkeleton from "@/components/back/liste_attente/TableSkeleton.vue";

    export default {
        name: "Index",
        components: {TableSkeleton, WaitingListWrapper },
        computed: {
            ...mapGetters({
                getUser: namespaces.auth + 'getUser',
            }),
        },
        data() {
            return {
                waitingList: null
            }
        },
        methods: {
            async getData() {
                let res = await axios.get('v1/admin/appointment/get-waiting-list?app_id=' + Vue.$config.app_id,{
                    headers: {
                        "content-type": "application/json",
                        "Accept": "application/json, text/plain",
                        "Authorization": "Bearer " + this.getUser.api_token
                    }
                })

                this.waitingList = res.data.waitingList
            }
        },
        mounted() {
            this.getData()
            EventBus.$on('refresh-waiting-list', () => {
                this.getData()
            })
        },
        beforeDestroy() {
            EventBus.$off('refresh-waiting-list')
        }
    }
</script>

<style scoped>

</style>