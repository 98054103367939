<template>
    <div class="card mb-0" ref="createModale" id="create-modale">
        <div class="card-header d-flex justify-content-between">
            <h5 class="modal-title">Informations sur ce rendez-vous</h5>
            <span class="clickable" @click="onDismiss"><i class="fa fa-times"></i></span>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-6">
                    <table>
                        <tr>
                            <td colspan="2"><h6>{{$t('liste-attente.info-patient')}}</h6></td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-user"></i></th>
                            <td>{{ appointment.user.full_name }}</td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-id-card"></i></th>
                            <td>{{ appointment.user.matricule ? appointment.user.matricule : $t('liste-attente.matricule-inconnu') }}</td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-envelope"></i></th>
                            <td>{{ appointment.user.email ? appointment.user.email :  $t('liste-attente.email-inconnu') }}</td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-phone"></i></th>
                            <td>{{ appointment.user.cellphone_number ? '+' + appointment.user.cellphone_number :  $t('liste-attente.telephone-inconnu') }}</td>
                        </tr>
                  </table>
                </div>
                <div class="col-md-6">
                    <table>
                        <tr>
                            <td colspan="2"><h6>{{$t('liste-attente.info-rdv')}}</h6></td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-user-md"></i></th>
                            <td>{{ 'Dr. ' + appointment.doctor.full_name }}</td>
                        </tr>
                        <tr>
                            <th><i class="bi bi-building"></i></th>
                            <td>{{ appointment.office_id ? appointment.office.name : $t('liste-attente.lieu-inconnu') }}</td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-stethoscope"></i></th>
                            <td>{{ appointment.reason_id ? appointment.reason.label : $t('liste-attente.motif-inconnu') }}</td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-calendar-week"></i></th>
                            <td>{{ formatDay(appointment.date_debut) + ' de ' + formatHour(appointment.date_debut) + ' à ' + formatHour(appointment.date_fin) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <table>
                        <tr>
                            <td colspan="2"><h6>{{$t('liste-attente.infos-generales')}}</h6></td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-calendar-alt"></i></th>
                            <td>{{ 'Rendez-vous enregistré le ' + formatDay(appointment.created_at) + ' à ' + formatHour(appointment.created_at) }}</td>
                        </tr>
                        <tr>
                            <th><i class="fas fa-comment-dots"></i></th>
                            <td>{{ appointment.comment ? appointment.comment : $t('liste-attente.aucun-commentaire') }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="card-footer d-flex bd-highlight">
            <button type="button" class="btn btn-danger btn-sm bd-highlight mr-auto" @click="onDelete()">{{ $t('liste-attente.refuser') }}</button>
            <button type="button" class="btn btn-secondary btn-sm bd-highlight mr-2" @click="onDismiss">{{ $t('liste-attente.fermer') }}</button>
            <button type="button" class="btn btn-success btn-sm bd-highlight" @click="onAccept()">{{ $t('liste-attente.accepter') }}</button>
        </div>
    </div>
</template>

<script>
    import moment from "moment/moment";
    import DeleteAppointmentModal from "../delete/DeleteAppointmentModal";
    import calendarApi from "@/api/calendar";
    import {EventBus} from "@/event-bus";

    export default {
        name: "AppointmentModal",
        props: {
            appointment: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
            }
        },
        methods: {
            formatDay(date) {
                if (date) {
                    return moment(date).format('DD/MM/YYYY')
                }
            },
            formatHour(date) {
                if (date) {
                    return moment(date).format('HH:mm')
                }
            },
            async onAccept() {
                this.$toast.open({
                    message: String(this.$t('liste-attente.acceptation-en-cours')),
                    type: 'info',
                })

                await calendarApi.update({
                    doctor_id: this.appointment.doctor_id,
                    date_debut: this.appointment.date_debut,
                    date_fin: this.appointment.date_fin,
                    pending: 0
                }, this.appointment.id)

                let res = await calendarApi.getDoctorUser(this.appointment.doctor_id, this.appointment.user_id)
                let resDoctorUser = await calendarApi.updateDoctorUser(res.data.doctor_user[0].id, this.appointment.id)

                this.$toast.open({
                    message: String(this.$t('liste-attente.acceptation-success')),
                    type: 'success',
                });

                this.onDismiss()
                EventBus.$emit('refresh-waiting-list')
            },
            onDelete() {
                this.$modal.show(DeleteAppointmentModal,{
                    appointment: this.appointment
                },{
                    name: 'DeleteAppointmentModal',
                    clickToClose: false,
                    adaptive: true,
                    height: 'auto',
                    scrollable: true,
                    transition: 'fade'
                })
            },
            onDismiss() {
                this.$modal.hide('AppointmentModal')
            }
        }
    }
</script>

<style scoped>
    table tr {
        height: 28px;
    }

    table tr th {
        width: 28px;
    }

    button {
        width: 80px;
    }
</style>
