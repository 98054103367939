<template>
    <div class="card mb-0 border-danger">
        <div class="card-header table-danger d-flex justify-content-between">
            <h5 class="modal-title" id="exampleModalLabel"><i class="bi bi-exclamation-triangle-fill mr-2"></i>{{$t('liste-attente.annulation')}}</h5>
            <span class="clickable" @click="onDismiss"><i class="fa fa-times"></i></span>
        </div>
        <div class="card-body">
            <div class="text-danger">
                {{$t('liste-attente.confirmer-annulation')}}
            </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-secondary mr-2" @click="onDismiss">Annuler</button>
            <button type="button" class="btn btn-sm btn-danger mr-2" @click="onConfirmation" :disabled="loading || !appointment_ids || appointment_ids.length === 0">
                <span v-if="loading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                <span v-if="loading" >{{$t('global.chargement')}}</span>
                <span v-if="!loading"><i class="bi bi-check mr-2"></i>{{$t('liste-attente.confirmer')}}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {namespaces} from "@/store/namespaces";
    import calendarApi from '../../../../../api/calendar';
    import { EventBus } from '@/event-bus';

    export default {
        name: "DeleteAppointmentModal",
        props: {
            appointment: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                loading: false,
                appointments: null,
                appointment_ids: [this.appointment.id]
            }
        },
        computed: {
            ...mapGetters({
                getIsLoggedIn: namespaces.auth + 'getIsLoggedIn',
                getUser: namespaces.auth + 'getUser',
                getSelectedReason: namespaces.calendar+'getSelectedReason',
                getSelectedDoctor: namespaces.calendar+'getSelectedDoctor',
                getSelectedOffice: namespaces.calendar+'getSelectedOffice',
                getSelectedSlot: namespaces.adminCalendar+'getSelectedSlot',
            }),
        },
        methods: {
            async onConfirmation() {
                if(this.appointment_ids.length === 0) {
                    this.$toast.info(String(this.$t('liste-attente.warning-selection-date')))
                    return
                }

                this.loading = true
                let res = await calendarApi.deleteMultiple({appointment_ids: this.appointment_ids})
                this.loading = false

                this.$toast.open({
                    message: String(this.$t('liste-attente.annulation-success')),
                    type: 'success',
                });

                this.$modal.hideAll()
                EventBus.$emit('refresh-waiting-list')
            },
            onDismiss() {
                this.$modal.hide('DeleteAppointmentModal')
            },
            onSelectAll() {
                this.appointment_ids = []
                this.appointments.forEach(a=> {
                    this.appointment_ids.push(a.id)
                })
            },
            onSelectNone() {
                this.appointment_ids = [this.appointment.id]
            }
        },
        async mounted() {
            let res = await calendarApi.getRepeatedAppointments(this.appointment.id)
            this.appointments = res.data.appointments
        }
    }
</script>

<style scoped>

</style>
