<template>
    <div v-else class="table-waiting table-responsive p-1">
        <table class="table table-striped table-hover text-center">
            <thead>
            <tr>
                <th colspan="3">Informations sur le patient</th>
                <th colspan="3">Informations sur le rendez-vous</th>
                <th>Date d'enregistrement</th>
                <th>Actions</th>
            </tr>
            <tr class="icon-row">
                <th><i class="fas fa-user"></i></th>
                <th><i class="fas fa-id-card"></i></th>
                <th><i class="fas fa-envelope"></i></th>
                <th><i class="fas fa-user-md"></i></th>
                <th><i class="fas fa-stethoscope"></i></th>
                <th><i class="fas fa-calendar-week"></i></th>
                <th><i class="fas fa-calendar-alt"></i></th>
                <th><i class="fas fa-ellipsis-h"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="i in 10">
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
                <td>
                    <PuSkeleton></PuSkeleton>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "TableSkeleton"
}
</script>

<style scoped>

</style>